<template>
  <div>
    <PageHeader :items="items" />
    <validation-observer ref="simpleRules">
      <div class="card">
        <div class="card-body">
          <b-row>
            <b-col cols="12" lg="6" md="6" class="mb-1">
              <validation-provider
                #default="{ errors }"
                name="Invoice Type"
                rules="required"
              >
                <label class="input-label form-label"
                  ><span style="color: red">*</span>&nbsp;{{
                    $t("Invoice Type")
                  }}
                </label>
                <multiselect
                  v-model="form.invoiceType"
                  :key="form.invoiceType"
                  :options="['invoice-correction', 'invoice', 'invoice-storno']"
                  :multiple="false"
                >
                </multiselect>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col cols="12" lg="6" md="6" class="mb-1">
              <validation-provider
                #default="{ errors }"
                name="Due Date"
                rules="required"
              >
                <DatePicker
                  v-model="form.dueDate"
                  :label="$t('Due Date')"
                  :required="true"
                  :isLeftIcon="true"
                  :leftIconName="'dateIcon'"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col cols="12" lg="6" md="6" class="mb-1">
              <div class="form-group">
                <label class="input-label form-label"
                  ><span style="color: red">*</span>&nbsp;{{ $t("Customer") }}
                </label>
                <validation-provider
                  #default="{ errors }"
                  name="Customer"
                  rules="required"
                >
                  <MultiSelectInput
                    v-model="form.companyId"
                    :options="customers"
                    label="companyName"
                    trackBy="id"
                    :key="form.companyId"
                    moduleName="customers"
                    :multiple="false"
                    ><template #option="{ props }">
                      <div>
                        <p>
                          {{ props.option.companyNumber }} - {{
                            props.option.companyName
                          }}
                        </p>
                      </div>
                    </template>
                  </MultiSelectInput>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </div>
            </b-col>
            <b-col cols="12" lg="6" md="6" class="mb-1">
              <validation-provider
                #default="{ errors }"
                name="Start Date"
                rules="required"
                ><DatePicker
                  v-model="form.startDate"
                  :label="$t('Start Date')"
                  :required="true"
                  :isLeftIcon="true"
                  :leftIconName="'dateIcon'"
                />
                <!-- <TextInput
                  v-model="form.startDate"
                  type="date"
                  :label="$t('Start Date')"
                  :tooltipMessage="$t('Start Date')"
                  :required="true"
                  :key="form.startDate"
                /> -->
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col cols="12" lg="6" md="6" class="mb-1">
              <validation-provider
                #default="{ errors }"
                name="End Date"
                rules="required"
                ><DatePicker
                  v-model="form.endDate"
                  :label="$t('End Date')"
                  :required="true"
                  :isLeftIcon="true"
                  :leftIconName="'dateIcon'"
                />

                <!-- <TextInput
                  v-model="form.endDate"
                  type="date"
                  :label="$t('End Date')"
                  :tooltipMessage="$t('End Date')"
                  :required="true"
                  :key="form.endDate"
                /> -->
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col cols="12" lg="6" md="6" class="mb-1">
              <DatePicker
                v-model="form.invoiceDate"
                :label="$t('Invoice Date')"
                :isLeftIcon="true"
                :leftIconName="'dateIcon'"
              />
              <!-- <TextInput
                v-model="form.invoiceDate"
                type="date"
                :label="$t('Invoice Date')"
                :tooltipMessage="$t('Invoice Date')"
                :key="form.invoiceDate"
              /> -->
            </b-col>
            <b-col cols="12" lg="6" md="6" class="mb-1">
              <TextInput
                v-model="form.externalOrderNumber"
                type="text"
                :label="$t('External Order Number')"
              />
            </b-col>
            <b-col cols="12" lg="6" md="6" class="mb-1">
              <label class="input-label form-label" for="cars">{{
                $t("Apply Reverse Charge")
              }}</label>

              <input
                id="active"
                class="form-check-input-custom ml-2"
                v-model="form.applyReverseCharge"
                type="checkbox"
              />
            </b-col>
            <b-col cols="12" lg="6" md="6" class="mb-1">
              <label class="input-label form-label" for="cars">{{
                $t("Custom Notes Field")
              }}</label>
              <textarea
                class="form-control"
                rows="5"
                v-model="form.customNotesFields"
              />
            </b-col>
          </b-row>
        </div>
      </div>
      <div class="card invoice-details">
        <div
          class="card-header d-flex align-items-center justify-content-between"
        >
          <h2>{{ $t("Draft") }}</h2>
          <h2>{{ form.startDate }}</h2>
        </div>
        <div class="card-body px-3">
          <div class="row mt-2 align-items-start">
            <div class="col-lg-4 col-md-4 col-12">
              <div class="d-flex align-items-center justify-content-between">
                <h4>{{ $t("Customer") }}</h4>
                <p v-if="form.companyId.companyName">
                  {{ form.companyId.companyName }}
                </p>
                <p v-else>-</p>
              </div>
              <div class="d-flex align-items-center justify-content-between">
                <h4>{{ $t("Invoice Nr.") }}</h4>
                <p>{{ $t("Draft") }}</p>
              </div>
            </div>
            <div class="col-lg-4 col-md-4 col-12">
              <div class="d-flex align-items-center justify-content-between">
                <h4>{{ $t("Created By") }}:</h4>
                <p>{{ user.first_name }} {{ user.last_name }}</p>
              </div>
              <div class="d-flex align-items-center justify-content-between">
                <h4>{{ $t("Due Date") }}:</h4>
                <p>{{ form.dueDate }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" aria-label="Products Header">
          <h3 class="card-title">
            {{ $t("Products") }}
          </h3>
        </div>
        <div class="card-body px-3" aria-label="Products Body">
          <div class="w-100">
            <div
              v-for="(products, index) in form.products"
              :key="index"
              class="mb-2"
            >
              <div class="row align-items-start">
                <div class="col-md-1">
                  <validation-provider
                    #default="{ errors }"
                    name="POS"
                    rules="required"
                  >
                    <text-input
                      v-model="products.pos"
                      :label="$t('POS')"
                      :required="true"
                      aria-label="POS Input"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>
                <div class="col-md-2">
                  <text-input
                    v-model="products.articleNumber"
                    :label="$t('Article Number')"
                    aria-label="Article Number Input"
                  />
                </div>
                <div class="col-md-2">
                  <validation-provider
                    #default="{ errors }"
                    name="Product Name"
                    rules="required"
                  >
                    <text-input
                      v-model="products.productName"
                      :label="$t('Product Name')"
                      :required="true"
                      aria-label="Product Name Input"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>

                <div class="col-md-1">
                  <validation-provider
                    #default="{ errors }"
                    name="Quantity"
                    rules="required"
                  >
                    <text-input
                      v-model="products.quantity"
                      :label="$t('Quantity')"
                      :required="true"
                      type="number"
                      @input="quantityChanged($event, index)"
                      aria-label="Quantity Input"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>
                <div class="col-md-1">
                  <validation-provider
                    #default="{ errors }"
                    name="Tax"
                    rules="required"
                  >
                    <text-input
                      v-model="products.tax"
                      :label="$t('Tax')"
                      :required="true"
                      type="number"
                      aria-label="Tax Input"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>
                <div class="col-md-2">
                  <validation-provider
                    #default="{ errors }"
                    name="Product Price"
                    rules="required"
                    class="w-100"
                  >
                    <text-input
                      v-model="products.productPrice"
                      :label="$t('Product Price')"
                      :required="true"
                      type="number"
                      @input="priceChanged($event, index)"
                      aria-label="Product Price Input"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>
                <div class="col-md-3 d-flex align-items-center gap-2">
                  <div class="w-100">
                    <label class="input-label form-label"
                      ><span style="color: red">*</span>&nbsp;{{
                        $t("Netto Total")
                      }}
                    </label>
                    <div class="mb-1 form-control">
                      <div>
                        {{
                          $formatter(
                            products.nettoTotal,
                            $i18n.locale,
                            "EUR",
                            false,
                            2,
                            2
                          )
                        }}
                      </div>
                    </div>
                  </div>
                  <div
                    style="margin-top: 6px"
                    role="button"
                    @click="deleteProducts(index)"
                    aria-label="Delete Products button"
                  >
                    <feather-icon icon="XIcon" size="30" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="form.products.length > 0"
            class="d-flex align-items-center justify-content-end mt-2"
          >
            <div class="product-total">
              <ul>
                <li>
                  <h3>{{ $t("Netto") }}</h3>
                  <p>
                    {{
                      $formatter(totalNetto, $i18n.locale, "EUR", false, 2, 2)
                    }}
                  </p>
                </li>
                <div v-if="!form.applyReverseCharge">
                  <li v-for="(tax, index) in calculateTax" :key="index">
                    <h3>Tax {{ tax.percent }}%</h3>
                    <p>
                      {{
                        $formatter(tax.amount, $i18n.locale, "EUR", false, 2, 2)
                      }}
                    </p>
                  </li>
                </div>
                <li class="total">
                  <h3>{{ $t("Total") }}</h3>
                  <p>
                    {{
                      $formatter(totalAmount, $i18n.locale, "EUR", false, 2, 2)
                    }}
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <b-button variant="primary" @click="addProducts" class="mt-2">
            <feather-icon :icon="'PlusIcon'" size="16" />
            <span>
              {{ $t("Add products") }}
            </span>
          </b-button>
        </div>
      </div>

      <div class="d-flex align-items-center justify-content-end">
        <b-button
          :disabled="isLoading"
          variant="primary"
          @click="createInvoice"
          class="d-flex align-items-center gap-1"
        >
          <feather-icon icon="PlusIcon" size="12" />
          <span>{{ $t("Create Invoice") }}</span>
        </b-button>
      </div>
    </validation-observer>
  </div>
</template>

<script>
import TextInput from "@/components/TextInput.vue";
import PageHeader from "@/components/PageHeader.vue";
import Multiselect from "vue-multiselect";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { mapGetters } from "vuex";
import { required, email } from "@validations";
import MultiSelectInput from "@/components/MultiSelectInput.vue";
import DatePicker from "@/components/DatePicker.vue";
import ElementsIcon from "@/components/elements/Icon.vue";
export default {
  components: {
    TextInput,
    PageHeader,
    ValidationProvider,
    ValidationObserver,
    Multiselect,
    MultiSelectInput,
    DatePicker,
    ElementsIcon,
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters("customers", ["customers"]),
    totalNetto() {
      return this.form.products.reduce((sum, product) => {
        return sum + parseFloat(product.nettoTotal || 0);
      }, 0);
    },
    totalAmount() {
      // Sum all the taxes from calculateTax and add totalNetto
      const totalTaxes = this.calculateTax.reduce((sum, tax) => {
        return sum + parseFloat(tax.amount);
      }, 0);

      return parseFloat(this.totalNetto + totalTaxes).toFixed(2);
    },
    calculateTax() {
      if (this.form.applyReverseCharge) {
        // If reverse charge is applied, all amounts should be zero
        return Object.keys(
          this.form.products.reduce((result, product) => {
            result[product.tax] = 0;
            return result;
          }, {})
        ).map((tax) => ({
          percent: tax,
          amount: "0.00",
        }));
      }

      const groupedProducts = this.form.products.reduce((result, product) => {
        const tax = product.tax;
        if (!result[tax]) {
          result[tax] = [];
        }
        result[tax].push(product);
        return result;
      }, {});

      const result = Object.entries(groupedProducts).map(([tax, products]) => ({
        percent: tax,
        amount: products.reduce((sum, product) => {
          const nettoTotal = parseFloat(product.nettoTotal) || 0;
          const taxAmount = (nettoTotal * parseFloat(tax)) / 100;
          return parseFloat(sum + taxAmount).toFixed(2);
        }, 0),
      }));

      return result;
    },
    items() {
      return [
        {
          text: "Dental Twin",
          to: "/home",
        },
        {
          text: this.$t("Invoices"),
          to: "/invoices",
        },
        {
          text: this.$t("Create"),
          active: true,
        },
      ];
    },
  },
  data() {
    return {
      isLoading: false,
      form: {
        invoiceType: "",
        companyId: "",
        dueDate: this.formatDate(new Date()),
        startDate: this.formatDate(new Date()),
        endDate: this.formatDate(
          new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
        ),
        invoiceDate: this.formatDate(new Date()),
        externalOrderNumber: "",
        applyReverseCharge: false,
        products: [],
        status: "draft",
        customNotesFields: "",
      },
    };
  },
  async created() {
    try {
      this.$store.commit("showLoader", true);
      await this.$store.dispatch("customers/list");
    } catch (e) {
      console.error("api error ___", e);
    } finally {
      this.$store.commit("showLoader", false);
    }
  },

  methods: {
    addProducts() {
      this.form.products.push({
        pos: this.form.products.length + 1,
        articleNumber: "",
        productName: "",
        productPrice: "",
        quantity: "",
        tax: "19",
        nettoTotal: "0.00",
      });
    },
    quantityChanged(event, index) {
      this.form.products[index].nettoTotal =
        event * this.form.products[index].productPrice;
    },
    formatDate(date) {
      let year = date.getFullYear();
      let month = ("0" + (date.getMonth() + 1)).slice(-2); // Add leading zero if needed
      let day = ("0" + date.getDate()).slice(-2); // Add leading zero if needed
      return `${year}-${month}-${day}`;
    },
    priceChanged(event, index) {
      this.form.products[index].nettoTotal =
        event * this.form.products[index].quantity;
    },
    async deleteProducts(index) {
      this.$swal({
        title: this.$t("Do you want to delete this record?"),
        text: this.$t("You can't revert your action"),
        type: "warning",
        customClass: "custom-delete-popup",
        showCancelButton: true,
        confirmButtonText: this.$t("Yes delete it!"),
        cancelButtonText: this.$t("No"),
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then(async (result) => {
        if (result.isConfirmed === true) {
          this.form.products.splice(index, 1);
        }
      });
    },
    async createInvoice() {
      this.$refs.simpleRules.validate().then(async (success) => {
        if (success) {
          const totalTaxes = this.calculateTax.reduce((sum, tax) => {
            return sum + parseFloat(tax.amount);
          }, 0);
          this.isLoading = true;
          await this.$store
            .dispatch("invoices/create", {
              ...this.form,
              companyId: this.form.companyId?.id ?? "",
              totalAmount: this.totalAmount,
              taxAmount: totalTaxes,
              netto: this.totalNetto,
            })
            .finally(() => {
              this.isLoading = false;
            });
          this.$router.push("/invoices");
        }
      });
    },
  },
};
</script>

<style scoped>
#nprogress {
  position: relative;
  z-index: 9999999;
}

.white-color {
  color: white !important;
}

.vgt-responsive {
  overflow-x: visible !important;
}
</style>
